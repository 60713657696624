.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  background-color: var(--p-50);
  padding: 80px 0;
}
.reviewCardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 100px;
}
.header2 {
  margin-bottom: 64px;
  text-align: center;
  color: var(--white);
}

.singleCard {
  display: flex;
  flex-direction: row;
  border-radius: 24px;
  border: 1px solid var(--p-60);
  background: var(--p-60);
  padding: 48px;
  width: 100%;
}
.imageContainer {
  width: 298px;
  flex-shrink: 0;
}
.clientImage {
  width: 298px;
  height: auto;
}
.detailsContainer {
  margin-left: 56px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
}
.description {
  color: var(--white);
  margin-top: 32px;
}
.company {
  display: flex;
  color: var(--white);
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-28);
  align-items: center;
  gap: 24px;
}
.position {
  color: #fff;
  border-right: 2px solid var(--white);
  padding-right: 24px;
}
.positionImg {
  width: 1.5rem;
  height: 1.5rem;
}
.mobileBottomLine {
  display: none;
}
.cardLogoImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}
.cardLogo {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
}
.cardLogoImage {
  display: inline-block;
  padding: 0 24px 16px 24px;
  cursor: pointer;
  margin: 0 50px;
  position: relative;
}
.rightLine {
  display: inline-block;
  width: 1px;
  background-color: var(--white);
  height: 40px;
}
.logoBottomLine {
  display: inline-block;
  width: 100%;
  position: absolute;
  border-bottom: 2px solid white;
  left: 0;
  bottom: 0;
}

@media (min-width: 1000px) and (max-width: 1384px) {
  .singleCard {
    width: 90%;
    margin: auto;
  }
}

@media (min-width: 600px) and (max-width: 1000px) {
  .singleCard {
    flex-direction: column;
    width: 90%;
    margin: auto;
    gap: 0;
    justify-content: space-between;
    align-items: center;
  }
  .description {
    font-weight: var(--font-weight-500);
  }
}
@media screen and (max-width: 600px) {
  .singleCard {
    flex-direction: column;
    align-items: center;
    min-height: auto;
    height: auto;
    width: calc(100% - 48px);
    margin: auto 24px;
    justify-content: space-between;
    padding: 32px;
    border-radius: 20px;
  }
  .header2 {
    padding: 0 24px;
  }
  .detailsContainer {
    padding: 0px;
    margin: 0;
  }
  .imageContainer {
    margin-bottom: 32px;
  }
  .clientImage {
    border-radius: 16px;
  }
  .quoteImage {
    width: 35px;
    height: 28px;
  }
  .company {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }
  .position {
    border: none;
  }
  .mobileBottomLine {
    display: inline-block;
    background-color: var(--white);
    height: 2px;
    width: 40px;
  }
  .cardLogoImageContainer {
    justify-content: unset;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    white-space: nowrap;
  }
  .cardLogoImageContainer::-webkit-scrollbar {
    display: none;
  }
  .cardLogoImage {
    padding: 0 15px 16px;
    margin: 0 32px;
  }
}

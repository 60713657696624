.swiper {
  max-width: var(--max-screen-width);
  width: 100%;
}

.swiper-slide {
  min-height: 394px;
}

.swiper-slide {
  width: 100%;
}

